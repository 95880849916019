import React, { useState } from "react";
import cmo from "~images/cmo.png";
import BasicModal from "../../basic-modal/basic-modal";
import { Popup } from "../popup";
import { popup } from "./popup-trigger.module.scss";
import { ShowWhen } from "../../show-when/ShowWhen";

export const PopupTrigger = () => {

  const [open, changeOpen] = useState(false);

  const click = () => {
    changeOpen(v => !v);
  };

  const close = () => {
    changeOpen(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <img src={cmo} onClick={click} />
      <ShowWhen condition={open}>
        <BasicModal className={popup}>
          <Popup closeModal={close} />
        </BasicModal>
      </ShowWhen>
    </>
  );

};
