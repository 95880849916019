import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import TypeAnimation from "react-type-animation";
import { PopupTrigger } from "../../components/cmo-services/popup-trigger";
import * as styles from "../../styles/pages/services/cmo.module.scss";

const ContentPage = () => (
  <Layout>
    <SEO
      title="Fractional CMO Services"
      description="The Simple Department offers on-demand Fractional CMO services to get you creative or strategic support whenever you need it (and never when you don't). "
    />
    <div className="container">
      <div className={`${styles.headerBlock} mb-5`}>
        <section className="page-heading">
          <TypeAnimation
            cursor={false}
            sequence={["Fractional CMO Services", 500]}
            className={"title"}
            wrapper="h1"
          />
        </section>
        <p className="pe-4">
          Not everyone needs the weight of a full agency behind their marketing
          – sometimes they just need quick creative support from talented,
          highly experienced, bright people. No flakey contractors, no missed
          deadlines, no radio silence, no egos.<br />You pick the services
          you want. We do it. You get what you want.
        </p>
        <div className={styles.headingImageWrapper}>
          <PopupTrigger />
        </div>
      </div>

      <Products>
        <div
          title="Dissecting Your Data | Marketing Consulting | 1 Hour"
          price="$250 CAD per hour"
          id="cmo-dissecting"
          subtitle="$250 CAD per hour"
        >
          <p>
            You’ve got stats on stats on stats, but can you identify what is
            noise and what can form actionable intelligence that allows you to
            scale and supports your go to market strategy? We will break it
            down together in this 1-hour session.
          </p>
        </div>
        <div
          title="Avoid Stupid Mistakes | Marketing Consulting | 1 Hour"
          price="$250 CAD per hour"
          id="cmo-mistakes"
          subtitle="$250 CAD per hour"
        >
          <p>
            Sometimes you need to talk things through. Perspective,
            objectivity and experience. That is what we bring to the
            conversation, but most importantly, we are not emotionally
            attached to your product, brand or idea, so we can evaluate it
            based on Total Addressable Market, Brand Positioning, your
            Go-To-Market, Product Innovation, Market-Fit – and even our
            gut-instinct on whether this will do well for you or not.
          </p>
        </div>
        <div
          title="An Honest Assessment | Marketing Consulting | 2 Hours"
          price="$500 CAD"
          id="cmo-honest"
          subtitle="$500 CAD"
        >
          <p>
            These are more open-ended conversations with no singular focus. We
            may cover why your brand messaging is not resonating, that your
            advertising campaigns are structured incorrectly, or that you are
            wasting your money on awareness ads because you are not supporting
            them with retargeted content and advertising. Or…. maybe, we have
            to be the ones to tell you that your brand is horrible, and the
            market isn’t buying it because they are not attracted to it.
          </p>
        </div>

        <div
          title="Keeping It Simple, since ’83 | Hourly Marketing Consulting"
          price="$250 CAD per hour"
          id="cmo-hr"
          subtitle="$250 CAD per hour"
        >
          <p>
            At The Simple Department, we are used to helping companies like
            yours assess, troubleshoot, and move forward. Sometimes, this can
            mean quickly changing course before we hit an iceberg. More often,
            this means successfully launching something with more clarity,
            focus, purpose, and most importantly – impact. If you’re not sure
            what you need to talk about, but you know you need to talk, this
            is the best place to start.
          </p>
        </div>

        <div
          title="CMO Launch Package | 3 Months"
          price="$18,000 CAD"
          id="cmo-3"
          subtitle="$18,000 CAD"
        >
          <p>
            Have an idea that you want to get off the ground quickly,
            efficiently and for a fair price? Our Launch Package is designed
            to give you the most important pieces to build momentum for your
            business. We’ve got your back so you can focus on launching your
            ideas to the world.
          </p>
        </div>
        <div
          title="CMO Growth Package | 6 Months"
          price="$30,000 CAD"
          id="cmo-6"
          subtitle="$30,000 CAD"
        >
          <p>
            Looking to take your business to the next level but unsure of
            where to invest your marketing budget? Our CMO Growth Package is
            specifically tailored to get your idea or business flourishing and
            build momentum. We are here to guide you as you grow your ideas
            into something incredible!
          </p>
        </div>
        <div
          title="CMO Build Package | 12 Months"
          price="$55,000 CAD"
          id="cmo-12"
          subtitle="$55,000 CAD"
        >
          <p>
            Ready to turn your small idea or business into something huge? Our
            CMO Build Package is all about turning your small business into a
            big deal.
          </p>
        </div>
      </Products>
    </div>
  </Layout>
);

export default ContentPage;
