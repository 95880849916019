import React, { useCallback, useState } from "react";
import closeSvg from "~static/svg/icons/close.svg";
import * as styles from "./index.module.scss";
import { Toggle } from "../../toggle/toggle";
import { ShowWhen } from "../../show-when/ShowWhen";
import step1 from "~images/cmo-services-steps/1.png";
import step2 from "~images/cmo-services-steps/2.png";
import step3 from "~images/cmo-services-steps/3.png";
import step4 from "~images/cmo-services-steps/4.png";
import step5 from "~images/cmo-services-steps/5.png";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stepper } from "./stepper";

export const Popup = ({ closeModal }) => {

  const [toggles, setToggles] = useState({
    screen1: true,
    screen2: true,
    screen3: true,
    screen4: true,
    screen5: true
  });

  const toggleChange = useCallback(
    (name, value) => {
      const [screen, toggle] = name.split("_");
      const result = { ...toggles };
      result[screen] = toggle === "toggle2" ? !value : value;
      setToggles(result);
    },
    [toggles]
  );

  return (
    <div className={styles.container}>

      <div id="align">
        <img src={closeSvg} alt="close" width="25" height="25" className={styles.close} onClick={closeModal} />
        <div className={`${styles.slideContent}`}>
          <Stepper className="vstack gap-3 align-items-start mt-5" activeStepIndex={0}></Stepper>
          <div className={`${styles.slideInfo}`}>

            <div>
              <div>

                <div className={styles.stepTitle}>
                  <h2>
                    1. Align
                  </h2>
                  <div>
                    <a href="#prioritize">SEE THE NEXT STEP</a>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>

                <div className="row my-4">
                  <div className="col-auto">
                    <Toggle checked={toggles.screen1}
                            name="screen1_toggle1"
                            className={styles.toggleA}
                            onChange={toggleChange}>
                      SIMPLY PUT
                    </Toggle>
                  </div>
                  <div className="col-auto">
                    <Toggle checked={!toggles.screen1}
                            className={styles.toggleA}
                            name="screen1_toggle2"
                            onChange={toggleChange}>
                      MARKETING TERMS
                    </Toggle>
                  </div>
                </div>

                <ShowWhen condition={toggles.screen1}>
                  <div className={styles.stageText}>
                    The biggest obstacle that start-ups and
                    smaller enterprises face is not having clear
                    alignment around how the business works,
                    what your brand is, and how you should grow.
                  </div>
                </ShowWhen>

                <ShowWhen condition={!toggles.screen1}>
                  <ul className={`vstack gap-3 ${styles.stageText}`}>
                    <li>
                      We hard code elements of the
                      business and brand that should be
                      informing and guiding our strategies
                      and decision making frameworks.
                    </li>
                    <li>
                      We then evaluate our DNA against
                      how the business and brand are
                      currently operating and set ourselves
                      up to identify the priorities that we
                      need to put time, energy and money
                      into to be able to eventually shift the
                      conversation towards scaling the
                      business.
                    </li>
                  </ul>
                </ShowWhen>
              </div>

              <div>
                <img src={step1} alt="" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div id="prioritize">
        <img src={closeSvg} alt="close" width="25" height="25" className={styles.close} onClick={closeModal} />
        <div className={`${styles.slideContent} ${styles.right}`}>

          <Stepper className="vstack gap-3 align-items-start mt-5" activeStepIndex={1}></Stepper>

          <div className={styles.slideInfo}>

            <div>

              <div>

                <div className={styles.stepTitle}>
                  <h2>
                    2. Prioritize
                  </h2>
                  <div>
                    <a href="#strategize">SEE THE NEXT STEP</a>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>

                <div className="row my-4">
                  <div className="col-auto">
                    <Toggle checked={toggles.screen2}
                            name="screen2_toggle1"
                            className={styles.toggleB}
                            onChange={toggleChange}>
                      SIMPLY PUT
                    </Toggle>
                  </div>
                  <div className="col-auto">
                    <Toggle checked={!toggles.screen2}
                            className={styles.toggleB}
                            name="screen2_toggle2"
                            onChange={toggleChange}>
                      MARKETING TERMS
                    </Toggle>
                  </div>
                </div>
                <ShowWhen condition={toggles.screen2}>
                  <div className={styles.stageText}>
                    Profitability and revenue are always going to be
                    on the list, but those are results of having the right
                    priorities and being laser focused on continual
                    growth/improvement and adjustments of those
                    priorities.
                  </div>
                </ShowWhen>
                <ShowWhen condition={!toggles.screen2}>
                  <ul className={`vstack gap-3 ${styles.stageText}`}>
                    <li>
                      We hard code elements of the
                      business and brand that should be
                      informing and guiding our strategies
                      and decision making frameworks.
                    </li>
                    <li>
                      We then evaluate our DNA against
                      how the business and brand are
                      currently operating and set ourselves
                      up to identify the priorities that we
                      need to put time, energy and money
                      into to be able to eventually shift the
                      conversation towards scaling the
                      business.
                    </li>
                  </ul>
                </ShowWhen>
              </div>

              <div>
                <img src={step2} alt="" />
              </div>

            </div>

          </div>
        </div>
      </div>

      <div id="strategize">
        <img src={closeSvg} alt="close" width="25" height="25" className={styles.close} onClick={closeModal} />
        <div className={`${styles.slideContent}`}>

          <Stepper className="vstack gap-3 align-items-start mt-5" activeStepIndex={2}></Stepper>

          <div className={styles.slideInfo}>
            <div>

              <div>

                <div className={styles.stepTitle}>
                  <h2>
                    3. Strategize
                  </h2>
                  <div>
                    <a href="#execute">SEE THE NEXT STEP</a>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>

                <div className="row my-4">
                  <div className="col-auto">
                    <Toggle checked={toggles.screen3}
                            name="screen3_toggle1"
                            className={styles.toggleA}
                            onChange={toggleChange}>
                      SIMPLY PUT
                    </Toggle>
                  </div>
                  <div className="col-auto">
                    <Toggle checked={!toggles.screen3}
                            className={styles.toggleA}
                            name="screen3_toggle2"
                            onChange={toggleChange}>
                      MARKETING TERMS
                    </Toggle>
                  </div>
                </div>

                <ShowWhen condition={toggles.screen3}>
                  <div className={styles.stageText}>
                    The biggest obstacle that start-ups and
                    smaller enterprises face is not having clear
                    alignment around how the business works,
                    what your brand is, and how you should grow.
                  </div>
                </ShowWhen>

                <ShowWhen condition={!toggles.screen3}>
                  <ul className={`vstack gap-3 ${styles.stageText}`}>
                    <li>
                      We hard code elements of the
                      business and brand that should be
                      informing and guiding our strategies
                      and decision making frameworks.
                    </li>
                    <li>
                      We then evaluate our DNA against
                      how the business and brand are
                      currently operating and set ourselves
                      up to identify the priorities that we
                      need to put time, energy and money
                      into to be able to eventually shift the
                      conversation towards scaling the
                      business.
                    </li>
                  </ul>
                </ShowWhen>
              </div>

              <div>
                <img src={step3} alt="" />
              </div>

            </div>

          </div>
        </div>
      </div>

      <div id="execute">
        <img src={closeSvg} alt="close" width="25" height="25" className={styles.close} onClick={closeModal} />
        <div className={`${styles.slideContent} ${styles.right}`}>

          <Stepper className="vstack gap-3 align-items-start mt-5" activeStepIndex={3}></Stepper>

          <div className={styles.slideInfo}>
            <div>
              <div>

                <div className={styles.stepTitle}>
                  <h2>
                    4. Execute
                  </h2>
                  <div>
                    <a href="#scale">SEE THE NEXT STEP</a>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>

                <div className="row my-4">
                  <div className="col-auto">
                    <Toggle checked={toggles.screen4}
                            name="screen4_toggle1"
                            className={styles.toggleB}
                            onChange={toggleChange}>
                      SIMPLY PUT
                    </Toggle>
                  </div>
                  <div className="col-auto">
                    <Toggle checked={!toggles.screen4}
                            className={styles.toggleB}
                            name="screen4_toggle2"
                            onChange={toggleChange}>
                      MARKETING TERMS
                    </Toggle>
                  </div>
                </div>
                <ShowWhen condition={toggles.screen4}>
                  <div className={styles.stageText}>
                    We have our learning objectives and we launch,
                    test, and iterate to find that path of least resistance
                    to growth and our other key objectives. The key is
                    knowing what you want to learn and are you able
                    to learn it, or will you just end up with noise.
                  </div>
                </ShowWhen>
                <ShowWhen condition={!toggles.screen4}>
                  <ul className={`vstack gap-3 ${styles.stageText}`}>
                    <li>
                      We hard code elements of the
                      business and brand that should be
                      informing and guiding our strategies
                      and decision making frameworks.
                    </li>
                    <li>
                      We then evaluate our DNA against
                      how the business and brand are
                      currently operating and set ourselves
                      up to identify the priorities that we
                      need to put time, energy and money
                      into to be able to eventually shift the
                      conversation towards scaling the
                      business.
                    </li>
                  </ul>
                </ShowWhen>
              </div>
              <div>
                <img src={step4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="scale">
        <img src={closeSvg} alt="close" width="25" height="25" className={styles.close} onClick={closeModal} />
        <div className={`${styles.slideContent}`}>

          <Stepper className="vstack gap-3 align-items-start mt-5" activeStepIndex={4}></Stepper>

          <div className={styles.slideInfo}>
            <div>
              <div>
                <h2>
                  5. Scale
                </h2>
                <div className="row my-4">
                  <div className="col-auto">
                    <Toggle checked={toggles.screen5}
                            name="screen5_toggle1"
                            className={styles.toggleA}
                            onChange={toggleChange}>
                      SIMPLY PUT
                    </Toggle>
                  </div>
                  <div className="col-auto">
                    <Toggle checked={!toggles.screen5}
                            className={styles.toggleA}
                            name="screen5_toggle2"
                            onChange={toggleChange}>
                      MARKETING TERMS
                    </Toggle>
                  </div>
                </div>
                <ShowWhen condition={toggles.screen5}>
                  <div className={styles.stageText}>
                    Scaling is not just about spending more.
                    It could be more about investing in more
                    powerful tools, processes, and operational
                    strength to make the actual business better,
                    rather than just advertising
                  </div>
                </ShowWhen>
                <ShowWhen condition={!toggles.screen5}>
                  <ul className={`vstack gap-3 ${styles.stageText}`}>
                    <li>
                      We hard code elements of the
                      business and brand that should be
                      informing and guiding our strategies
                      and decision making frameworks.
                    </li>
                    <li>
                      We then evaluate our DNA against
                      how the business and brand are
                      currently operating and set ourselves
                      up to identify the priorities that we
                      need to put time, energy and money
                      into to be able to eventually shift the
                      conversation towards scaling the
                      business.
                    </li>
                  </ul>
                </ShowWhen>
              </div>
              <div>
                <img src={step5} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );

};
