import React from "react";
import { ShowWhen } from "../../../show-when/ShowWhen";
import * as styles from "./index.module.scss";

const steps = [
  {
    label: "1. Align",
    href: "#align"
  },
  {
    label: "2. Prioritize",
    href: "#prioritize"
  },
  {
    label: "3. Strategize",
    href: "#strategize"
  },
  {
    label: "4. Execute",
    href: "#execute"
  },
  {
    label: "4. Scale",
    href: "#scale"
  }
];

export const Stepper = ({ activeStepIndex, className }) => {

  const anchors = steps.map((s, i) => {
    return (
      <>
        <a className={activeStepIndex === i ? styles.active : ""} href={s.href}>
          {s.label}
        </a>
        <ShowWhen condition={i !== steps.length - 1}>
          <div className={styles.divider}></div>
        </ShowWhen>
      </>
    );
  });

  return (
    <div className={className}>
      <h1>
        Our roadmap to success
      </h1>
      <div className={`${styles.steps}`}>
        {anchors}
      </div>
    </div>
  );
};
