import React, { useCallback } from "react";
import { toggle } from "./toggle.module.scss";

export const Toggle = ({ children, className, checked, onChange, name }) => {

  const change = useCallback(
    (e) => {
      onChange && onChange(name, e.target.checked);
    },
    [name, onChange]
  );

  return (
    <div className={`${toggle} ${className || ""}`}>
      <input type="checkbox" onChange={change} checked={checked} />
      <div>
        {children}
      </div>
    </div>
  );

};
