import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./basic-modal.scss";

const BasicModal = ({ children, className, clickOutside }) => {

  const refContainer = useRef(document.createElement("div"));

  useEffect(() => {
    const container = refContainer.current;
    container.classList.add("basic-modal");
    className && container.classList.add(className);
    container.addEventListener("click", e => {
      if (e.target === container && clickOutside) {
        clickOutside();
      }
    });
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(refContainer.current);
    };
  }, []);

  return ReactDOM.createPortal(
    children,
    refContainer.current
  );
};

export default BasicModal;
